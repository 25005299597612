<template>
  <div>
    <div style="padding:10px"
         class="user_info"
         v-if="userInfo">
      <span> 学生姓名：{{userInfo.nickname || userInfo.truename}} </span>
      <span>学生手机号：{{userInfo.verifiedMobile}}</span>
      <span>用户ID：{{userInfo.id}}</span>
      <span> 学校:{{userInfo.school_name}}</span>
      <span>班级:{{userInfo.grade_name}}班 {{userInfo.wenli}} 科 {{userInfo.paper_district_name}}</span>

    </div>
    <el-table border
              stripe
              highlight-current-row
              show-summary
              :summary-method="getSummaries"
              :max-height="$store.state.tableHeight"
              :data="tableData"
              style="width: 100%">
      <el-table-column prop="subject_name"
                       align="center"
                       label="科目" />
      <el-table-column prop="grade_name"
                       align="center"
                       label="年级" />
      <el-table-column prop="count_all"
                       align="center"
                       label="总提交试卷数" />
      <el-table-column prop="zhengjuan_count"
                       align="center"
                       label="整卷测试数" />
      <el-table-column prop="teacher_count"
                       align="center"
                       label="教师试卷数" />
      <el-table-column prop="count_submitted_with_image"
                       align="center"
                       label="带图像(需要批改)试卷数" />
      <el-table-column prop="count_checked"
                       align="center"
                       label="已全部批改试卷数" />
      <el-table-column prop="free_count"
                       align="center"
                       label="自由选题数" />
      <el-table-column prop="chapter_count"
                       align="center"
                       label="章节训练数" />
      <el-table-column prop="knowledge_count"
                       align="center"
                       label="知识点训练数" />
      <el-table-column prop="totalPaperScore"
                       align="center"
                       label="试卷总分" />
      <el-table-column prop="userScoreRate"
                       align="center"
                       label="学生得分率" />
    </el-table>
    <pagination ref="pagination"
                v-show="false"
                :get-data="initData" />
  </div>
</template>

<script>
export default {
  data () {
    return {
      tableData: [],
      form: {},
      userInfo: {},
      sums: {},
      userInfo: null
    }
  },
  methods: {
    initData () {
      this.form = JSON.parse(this.$route.query.form)
      this.$http({
        url: '/api/v1/student/subject_detail',
        method: 'get',
        params: this.form
      }).then(res => {
        this.userInfo = res.data.user_data
        this.tableData = res.data.list.list
        // console.log('res', res.data.user_data)
        this.sums = res.data.list.sums
      })
    },
    // 统计行
    getSummaries () {
      const sums = [];
      sums[0] = ''
      sums[1] = ''
      sums[2] = this.sums.count_all
      sums[3] = this.sums.zhengjuan_count
      sums[4] = this.sums.count_edu
      sums[5] = this.sums.count_submitted_with_image
      sums[6] = this.sums.count_checked
      sums[7] = this.sums.count_free
      sums[8] = this.sums.chapter_count
      sums[9] = this.sums.knowledge_count
      sums[10] = this.sums.totalPaperScore
      sums[11] = this.sums.userScoreRate
      return sums;
    },

  }
}
</script>

<style lang="scss" scoped>
.user_info {
  span {
    padding-right: 20px;
  }
}
</style>